import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { CommunicationService } from 'app/services/communication.service'
import { NavigationService } from 'app/services/navigation.service'

@Component({
  selector: 'app-question-generic-inputs',
  templateUrl: './question-generic-inputs.component.html',
  styleUrls: ['./question-generic-inputs.component.scss']
})
export class QuestionGenericInputsComponent implements OnInit {

  @Input() public parentFormGroup
  @Input() public control
  @Input() public formSubmitAttempt
  @Input() public field
  @Input() public index: number
  @Input() public lastQuestion = false
  @Output() public readonly questionChangedAlert: EventEmitter<any> = new EventEmitter()
  @Output() public readonly scrollToNext: EventEmitter<any> = new EventEmitter()
  @Output() public readonly infoToggled: EventEmitter<number> = new EventEmitter()

  private originalInputNotes = []

  constructor(private communication: CommunicationService,
              private navigate: NavigationService,
    ) {
  }

  ngOnInit(): void {
    this.originalInputNotes = this.field.inputs.map(i => i.inputNotes);
  }

  public async handleConfirm() {
    if (this.field.confirmAction) {
      const state = {};

      for (let i = 0; i < this.field.inputs.length; i++) {
        const input = this.field.inputs[i];
        state[input.id] = this.control.value[i];
      }

      let context = await this.navigate.getContext();
      const messages = await this.communication.genericRequest(this.field.confirmAction, { state, context });

      for (let id of Object.keys(messages)) {
        const index = this.field.inputs.findIndex(i => i.id === id);

        if (index > -1) {
          this.field.inputs[index].inputNotes = [this.originalInputNotes[index], messages[id]].filter(m => m).join("\n");
        }
      }
      this.scrollToNext.emit(this.index);
    } else {
      this.scrollToNext.emit(this.index);
    }
  }
}
