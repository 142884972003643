import {
  AfterViewInit,
  Component, ElementRef, HostListener,
  Input,
  OnInit, Renderer2, ViewChild, ViewContainerRef, ViewEncapsulation
} from '@angular/core'
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { DialogConfig } from 'app/components/dialog/dialog-config'
import { DialogRef } from 'app/components/dialog/dialog-ref'
import { DialogService } from 'app/components/dialog/dialog.service'
import { QuestionCheckboxesComponent } from 'app/components/uicomponents/question-checkboxes/question-checkboxes.component'
import { QuestionComputeComponent } from 'app/components/uicomponents/question-compute/question-compute.component'
import { QuestionGenericInputsComponent } from 'app/components/uicomponents/question-generic-inputs/question-generic-inputs.component'
import { QuestionRadioButtonsComponent } from 'app/components/uicomponents/question-radio-buttons/question-radio-buttons.component'
import { QuestionTextareaComponent } from 'app/components/uicomponents/question-textarea/question-textarea.component'
import { PageInterface } from 'app/interfaces/page.interface'
import { AnalyticsService, Event } from 'app/services/analytics'
import { NavigationService } from 'app/services/navigation.service'
import { UtilitiesService } from 'app/services/utilities.service'
import { checkboxValidator } from 'app/utils/checkbox-validator'
import { FadeInAnimation } from 'app/utils/fade-in-animation'
import * as _ from 'lodash'
import smoothscroll from 'smoothscroll-polyfill/dist/smoothscroll.js'

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
  animations: [
    FadeInAnimation.animations
  ],
  encapsulation: ViewEncapsulation.None
})

export class QuestionComponent implements PageInterface, OnInit, AfterViewInit {
  private static readonly TAG = 'comp::Question'

  get questionsForms() {
    return this.questionForm.get('questions') as FormArray
  }

  @Input() public data: PnPage
  @Input() public context: any
  @ViewChild('scrollable_wrap', { static: false }) private scrollableWrap: ElementRef
  @ViewChild('blurLayer', { static: false }) private blurLayer: ElementRef
  @ViewChild('container', { read: ViewContainerRef, static: false }) private container: ViewContainerRef

  // Array of components dynamically generated
  public dynamicComponents = {  radio: QuestionRadioButtonsComponent,
    checkbox: QuestionCheckboxesComponent,
    generic: QuestionGenericInputsComponent,
    textarea: QuestionTextareaComponent,
    compute: QuestionComputeComponent }

  // Object containing all the @Outputs() of the components
  public otp = { infoToggled: evt => this.infoToggled(),
    scrollToNext: evt => this.scrollToNext(evt),
    onClicked: evt => (this.snapScroll ? this.scrollToNext(evt) : null),
    questionChangedAlert: evt => this.questionChangedAlert(evt),
    otherTextChangedAlert: evt => this.otherTextChangedAlert(evt),
    onCheckChanged: evt => this.onCheckChange(evt),
    onCheckNoteChanged: evt => this.onCheckNoteChange(evt),
    skipStep: evt => this.skipStep(evt),
    onCheckboxSubfieldChange: evt => this.onCheckboxSubfieldChange(evt)}

  public amountUnansweredQuestions = 0
  public output = { }
  public storedCheckboxesValues = { }
  public questionForm: FormGroup
  public formSubmitAttempt = false
  public snapScroll = false
  public alertTriggered = false
  public stopSnap = false
  public skipText = "";
  public isSkipped = false
  public allowPartialCompletion = false;

  private questionEls
  private triggerAlertOnChange = false
  private onQuestionChangedDialog: DialogRef = null
  private needsPolyfill = false
  private indexFocusedQuestion = 0

  constructor(
    private fb: FormBuilder,
    private navigate: NavigationService,
    private renderer: Renderer2,
    private elem: ElementRef,
    private analyticsService: AnalyticsService,
    private dialog: DialogService,
    private utilService: UtilitiesService,
    private translate: TranslateService ) {
  }

  @HostListener('window:resize', []) public onResize(): void {
    this.setBlurredArea()
  }

  public ngOnInit() {
    this.questionForm = this.fb.group({
      questions: this.fb.array([])
    })

    if (_.get(this.data.header, 'scrollBehaviour') === 'snap') {
      this.snapScroll = true
    }

    if (_.get(this.data.header, 'allowPartialCompletion') === true) {
      this.allowPartialCompletion = true
    }

    if (this.data.body.fields.length > 0) {
      this.data.body.fields.forEach((field, i) => {
          if (field.type === 'generic') {
            const inputs = this.fb.array([])

            if (field.skipped) {
              this.output[field.skipId] = true;
            }

            field.inputs.forEach(input => {
              if (!field.skipped) {
                this.output[input.id] = null
              }

              input.type = input.input
              inputs.push(this.addQuestion(input))
              if (!field.skipped && (input.value || input.value === 0)) {
                this.prepareOutput(input.value, input, true)
              }
              if (!field.skipped && input.selected || input.selected === 0) {
                this.prepareOutput(input.selected, input, true)
              }
            })
            this.questionsForms.push(inputs)
          } else {
            if (field.type !== 'compute' && !field.skipped) {
              this.output[field.id] = null
            }
            this.questionsForms.push(this.addQuestion(field))
            if (field.skipped) {
              this.output[field.skipId] = true;
            } else if (field.value || field.value === 0) {
              this.prepareOutput(field.value, field, true)
            }
            if (!field.skipped && (field.selected || field.selected === 0)) {
              this.prepareOutput(field.selected, field, true)
            }
          }
        }
      )
      this.checkAlert()
      if (!this.allowPartialCompletion) {
        this.triggerAlertOnChange = this.questionsForms.valid
      } else {
        this.setupPartComplValidators()
      }
    }

    this.questionsForms.controls.forEach((control, i) => {
      const field = this.data.body.fields[i];

      if (field.skipped) {
        control.disable();
      }

      control.valueChanges.subscribe(value => {
        if (!Array.isArray(value)) {
          this.prepareOutput(value, field, false)
        } else {
          if (this.allowPartialCompletion && control instanceof FormArray) {
            this.updateInputValidators(control as FormArray)
          }
          value.forEach((val, x) => {
            const input = field.inputs[x]
            if (val !== '' ) {
              this.prepareOutput(isNaN(val) ? val : Number(val), input, false)
            }
          })
        }
        this.updateErrorCounter()
        this.checkAlert()
      })
    })

    this.skipText = this.translate.instant('questionnaire.skip');
  }

  // Set up validators for pages that only require partial completion
  private setupPartComplValidators() {
    // Apply the validator to ensure at least one FormArray is fully completed
    this.questionsForms.setValidators(this.atLeastOneFieldComplete())
    this.questionsForms.updateValueAndValidity()
  }

  // Custom validator to check if at least one FormArray is fully completed
  private atLeastOneFieldComplete(): ValidatorFn {
    return (formArray: AbstractControl): ValidationErrors | null => {
      const fields = formArray as FormArray

      let atLeastOneComplete = false

      fields.controls.forEach(control => {
        if (control instanceof FormArray) {
          const allControlsFilled = control.controls.every(innerControl => !!innerControl.value)
          if (allControlsFilled) {
            atLeastOneComplete = true
          }
        }
      })
      if (atLeastOneComplete) {
        return null
      }
      return { atLeastOneRequired: true } // Invalid if no array is fully filled
    }
  }

  private updateInputValidators(inputs: FormArray) {
    const anyControlFilled = inputs.controls.some(control => !!control.value)
    // If any control is filled, make all other controls in the inner array required
    inputs.controls.forEach(control => {
      if (anyControlFilled) {
        control.setValidators([Validators.required])
      } else {
        control.clearValidators()
      }
      control.updateValueAndValidity({ emitEvent: false })
    })
  }

  public prepareOutput(value, field, searchById) {
    if (value == null || value === '') {
      this.output[field.id] = null
      return
    }
    this.output[field.id] = { }
    if (field.options && field.type !== 'checkbox') {
      this.output[field.id] = this.getFormattedValue(value, field.options, searchById)
    } else if (field.type !== 'checkbox') {
      this.output[field.id] = { value, id: field.id }
    }

    if (field.type === 'radio' && field.options.filter(o => o.id === 'other').length > 0) {
      if (this.output[field.id].other === null || this.output[field.id].other === undefined) {
        this.output[field.id].other = { id: field.id + '.other' , value: field.other };
      }
    }

    this.navigate.localOutput = this.output
  }

  public getFormattedValue(value, options, searchById) {
    // search option by value or by ID. depending on where the value comes from - number if from GUI - ID if from middleware
    const pos = searchById ? options.map(e => e.id).indexOf(value) :
      options.map(e => e.value).indexOf(value)
    if (pos !== -1) {
      return options[pos]
    } else {
      return { value }
    }
  }

  public async ngAfterViewInit() {
    if (!('scrollBehavior' in document.documentElement.style)) {
      this.needsPolyfill = true
    }
    if (this.needsPolyfill) await smoothscroll.polyfill()
    this.questionEls = this.elem.nativeElement.querySelectorAll('.question')
    if (this.snapScroll) setTimeout(() => this.setBlurredArea())
  }

  private needsRequiredValidation(field: any): boolean {
    if (field.required) {
      if (field.skipId && this.output[field.skipId]) {
        return false;
      } else {
        return true;
      }
    }

    return false;
  }

  public addQuestion(field): FormControl {
    switch (field.type) {
      case 'select':
      case 'radio':
        return this.fb.control(field.selected ?
          field.options.filter(e => e.id === field.selected)[0].value : '', [this.needsRequiredValidation(field) ? Validators.required : null])
      case 'number':
      case 'input-toggle':
        this.getMinMaxValue(field)
        return this.fb.control(field.value !== null ? field.value : '', this.getValidators(field))
      case 'template':
      case 'textarea':
        return this.fb.control(field.value !== null ? field.value : '', this.getValidators(field))
      case 'checkbox':
        this.storedCheckboxesValues[field.id] = field.options.filter(e => e.checked === true)
        this.output = { ...this.output, ...this.storedCheckboxesValues }
        if (field.subfield && field.subfield.value) {
          field.options.filter(option => option.id === field.subfield.trigger)[0].notes = field.subfield.value
        }
        return this.fb.control(this.storedCheckboxesValues[field.id].length > 0
          ? true : null, [this.needsRequiredValidation(field) ? checkboxValidator(field.options) : null])
      case 'compute':
        return this.fb.control("Compute")
      default:
        return this.fb.control('') // ToDo: Determine best way to handle no matching type
    }
  }

  public infoToggled() {
    this.stopSnap = true
    this.setBlurredArea()
    setTimeout(() => {
      // TODO find a better solution for this.
      // this is a workaround to prevent a bug on IOS mobile
      if (this.indexFocusedQuestion === this.questionEls.length - 1 && this.stopSnap && this.needsPolyfill) {
        this.scrollableWrap.nativeElement.scrollBy(0, -1)
      }
      this.stopSnap = false
    }, 450)
  }

  public setBlurredArea() {
    if (this.snapScroll) {
      const flagHeight = this.scrollableWrap.nativeElement.offsetHeight - this.questionEls[this.indexFocusedQuestion].offsetHeight
      this.renderer.setStyle(this.blurLayer.nativeElement, 'height' , (flagHeight > 0 ? flagHeight : 0) + 'px')
      if (this.indexFocusedQuestion === this.questionEls.length - 1) this.renderer.setStyle(this.blurLayer.nativeElement, 'opacity' , '0')
      else this.renderer.setStyle(this.blurLayer.nativeElement, 'opacity' , '.9')
    }
  }

  public onScroll() {
    if (this.snapScroll) {
      this.questionEls.forEach((el, k) => {
        const offsetTop = el.getBoundingClientRect().top
        const offsetBottom = el.getBoundingClientRect().bottom
        if (offsetTop < 200 && offsetTop >= -20 && offsetBottom > 200 &&  k !== this.indexFocusedQuestion ) {
          this.indexFocusedQuestion = k
          this.setBlurredArea()
        }
      })
    }
  }

  public checkAlert() {
    if (!this.data.alert || this.data.alert.alwaysVisible) return
    let tempCond: string
    this.alertTriggered = false
    Object.keys(this.output).forEach(id => {
        this.data.alert.alertConditions.forEach(condition => {
          if (!!this.output[id] && condition.includes(id)) {
            tempCond = condition
            Object.keys(this.output).forEach(ID => {
              tempCond = tempCond.replace(ID, !!this.output[ID] ? this.output[ID].value : undefined)
            })
            try {
              const saferEval = require('safer-eval')
              if (tempCond && saferEval(tempCond)) {
                this.alertTriggered = true
              }
            } catch (e) {
              // do not throw error. input number will deal with it
            }

          }
        })
      }
    )
  }

  public getMinMaxValue(field) {
    for (const validation of field.validation) {
      if (validation.condition.toLowerCase() === 'minmax' && validation.attributes.values) {
        field.min = validation.attributes.values[0]
        field.max = validation.attributes.values[1]
      }
    }
  }

  public onSkipField(e: any, field: any, control: any) {
    this.output[field.skipId] = e.target.checked;
    if (e.target.checked) {
      this.isSkipped = true
      control.disable();
      control.reset();
    } else {
      control.enable();
      this.isSkipped = false
    }
  }

  public skipStep(e: any): void {
    e.preventDefault()
    const skip = this.data.body.fields[this.indexFocusedQuestion].skip
    if (skip && skip.direction) {
      this.navigate.jumpTo(skip.direction, { })
    }
  }

  private getValidators(field) {
    const validators = []
    if (this.needsRequiredValidation(field)) validators.push(Validators.required)
    if (field.validation) {
      field.validation.forEach(val => {
        if (val.condition === 'RegExp') validators.push(Validators.pattern(val.attributes.regexp))
        if (val.condition === 'MinMax') {
          validators.push(Validators.min(val.attributes.values[0]))
          validators.push(Validators.max(val.attributes.values[1]))
        }
      })
    }
    return validators
  }

  public onCheckNoteChange(ev) {
    const index = this.storedCheckboxesValues[this.data.body.fields[ev.i].id].findIndex(cb => cb.id === ev.id);
    if (index > -1) {
      const found = this.storedCheckboxesValues[this.data.body.fields[ev.i].id][index]
      this.storedCheckboxesValues[this.data.body.fields[ev.i].id][index] = {...found, notes: ev.event.target.value};
      this.output = {...this.storedCheckboxesValues, ...this.output}
      this.navigate.localOutput = this.output
    }
  }

  public onCheckChange(ev) {
    const res = this.data.body.fields[ev.i].options[ev.index]
    const deselctOption = this.data.body.fields[ev.i].options.filter(option => option.deselectAll === true)[0]
    res.checked = ev.event.target.checked

    if (!res.checked && res.notes) {
      delete res.notes;
    }

    if (this.data.alert && !this.data.alert.alwaysVisible) {
      if (res.deselectAll) {
        this.alertTriggered = false
      } else {
        const options = this.data.body.fields[ev.i].options.length
        for (const option of this.data.body.fields[ev.i].options) {
          if (option.checked) {
            this.data.alert.alertConditions.forEach(condition => {
              if (parseInt(option.value) === parseInt(condition)) {
                this.alertTriggered = true
              }
            })
            if (this.alertTriggered === true) {
              break
            }
          } else if (parseInt(option.value) === options) {
            this.alertTriggered = false
          }
        }
      }
    }

    if (ev.event.target.checked && !res.deselectAll) {
      this.storedCheckboxesValues[this.data.body.fields[ev.i].id].push(res)
      if (!!deselctOption && deselctOption.checked) {
        this.storedCheckboxesValues[this.data.body.fields[ev.i].id].splice(0, 1)
        deselctOption.checked = false
      }
    } else if (ev.event.target.checked && res.deselectAll) {
      this.data.body.fields[ev.i].options.forEach(option => option.checked = false)
      res.checked = true
      this.storedCheckboxesValues[this.data.body.fields[ev.i].id] = []
      this.storedCheckboxesValues[this.data.body.fields[ev.i].id].push(res)
    } else {
      const indexToBeRemoved = this.storedCheckboxesValues[this.data.body.fields[ev.i].id].map(e => e.id).indexOf(ev.id)
      this.storedCheckboxesValues[this.data.body.fields[ev.i].id].splice(indexToBeRemoved, 1)
    }
    if (this.data.body.fields[ev.i].subfield && this.isTriggerBoxChecked(this.data.body.fields[ev.i].subfield.trigger, ev.i)) {
      this.questionsForms.controls[ev.i].setErrors({ required: true })
    }
    this.updateErrorCounter()
    this.output = { ...this.output, ...this.storedCheckboxesValues }
    this.navigate.localOutput = this.output
  }

  public scrollToNext(i) {
    if (i < this.questionEls.length - 1) {
      setTimeout(() => {
        this.questionEls[i + 1].scrollIntoView({ behavior: 'smooth', block: 'start' })
      }, 300)
    } else {
      setTimeout(() => {
        // Scroll to the bottom of the page
        this.scrollableWrap.nativeElement.scrollTo({
          top: this.scrollableWrap.nativeElement.scrollHeight,
          behavior: 'smooth'
        })
      }, 300)
    }
  }

  public triggerValidation() {
    this.formSubmitAttempt = true
    const leftQuestions = []
    const leftQuestionsTags = []
    this.questionsForms.controls.forEach((element, i) => {
      if (!element.valid) {
        leftQuestions.push(i)
        leftQuestionsTags.push(this.data.body.fields[i].questionTag)
      }
    })
    this.amountUnansweredQuestions = leftQuestions.length
    if (this.amountUnansweredQuestions === 0) {
      setTimeout(() => this.questionEls[0].scrollIntoView({ behavior: 'smooth', block: 'start' }))
    } else {
      setTimeout(() => this.questionEls[leftQuestions[0]].scrollIntoView({ behavior: 'smooth', block: 'start' }))
    }
    this.analyticsService.event(Event.StepContinueError, {
      ref_id1: this.data.header.id, ref_id2: JSON.stringify(leftQuestionsTags)
    })
  }

  public isTriggerBoxChecked(triggerID, i) {
    const res = this.data.body.fields[i].options.filter(e => (e.id === triggerID && e.checked))
    return res.length > 0
  }

  public onCheckboxSubfieldChange(ev) {
    this.output[this.data.body.fields[ev.i].id].filter(option => option.id === ev.trigger)[0].notes = ev.event.target.value
    if (ev.event.target.value !== '') {
      this.questionsForms.controls[ev.i].setErrors(null)
      this.updateErrorCounter()
    }
  }

  private updateErrorCounter() {
    setTimeout(() => this.amountUnansweredQuestions = this.questionsForms.controls.filter(control => !control.valid).length)
  }

  public otherTextChangedAlert(event: {value: string, id: string}) {
    if (this.output[event.id]) {
      this.output[event.id].other = { id: event.id + ".other", value: event.value };
    }
  }

  public questionChangedAlert(e) {
    if (this.triggerAlertOnChange && !this.utilService.getQuestionChangedDialog()) {
      if (_.get(e.target , 'type') === 'radio') {
        e.target.checked = false
      }
      e.preventDefault()

      this.utilService.setQuestionChangedDialog(true)
      this.triggerAlertOnChange = false
      const config = new DialogConfig<any>()
      config.backDrop = true
      config.title = this.translate.instant('questionChangedAlert.title')
      config.subTitle = this.translate.instant('questionChangedAlert.body')
      config.doneLabel = this.translate.instant('questionChangedAlert.confirm')
      config.cancelLabel = this.translate.instant('questionChangedAlert.cancel')
      this.onQuestionChangedDialog = this.dialog.open(null, config)
      this.onQuestionChangedDialog.done.subscribe(() => {
        this.onQuestionChangedDialog.close()
        e.target.click()
        if (_.get(e.target , 'type') === 'radio') {
          e.target.checked = true
        }
      })
      this.onQuestionChangedDialog.cancel.subscribe(() => {
        this.onQuestionChangedDialog.close()
      })
    }
  }

  public navigateChange = (to: any): void => {
    const filteredOutput = { ...this.output };

    for (let field of this.data.body.fields) {
      if (field.skipId && this.output[field.skipId]) {
        if (field.id) {
          if (filteredOutput[field.id] !== undefined) {
            delete filteredOutput[field.id];
          }
        }

        for (let input of field.inputs) {
          if (filteredOutput[input.id] !== undefined) {
            delete filteredOutput[input.id];
          }
        }
      }
    }

    const data = {
      answer: {
        toStepId: this.data.header.id,
        inputs: filteredOutput
      },
      context: null
    }
    if (this.questionForm.valid) {
      if (to.direction === 'next' && to.action === 'getPage') {
        this.navigate.nextPage(data)
      }
    } else {
      this.triggerValidation()
    }
  }
}
