import { Component, Input, OnChanges, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-error-state-bar',
  templateUrl: './error-state-bar.component.html',
  styleUrls: ['./error-state-bar.component.scss']
})
export class ErrorStateBarComponent implements OnChanges {
  @Input() public amount?: number
  public string: string
  constructor(private translate: TranslateService) {
  }
  public ngOnChanges(): void {
    switch (this.amount) {
      case 0: return this.string = this.translate.instant('error.unansweredQuestionsAtLeastOne')
      case 1: return this.string = this.translate.instant('error.unansweredQuestions1')
      case 2: return this.string = this.translate.instant('error.unansweredQuestions2')
      default : return this.string = this.translate.instant('error.unansweredQuestions+', { amount: this.amount })
  }}
}
